var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "", xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "card pa-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header d-flex justify-space-between align-center",
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "secondary-text text-lg-left form-title mb-4",
                        },
                        [_vm._v("Overzicht Rollen & Rechten")]
                      ),
                    ]
                  ),
                  _c(
                    "v-card",
                    { attrs: { flat: "", elevation: 0 } },
                    [
                      _c(
                        "v-tabs",
                        { attrs: { vertical: "" } },
                        [
                          _vm._l(_vm.roles, function (role) {
                            return _c("v-tab", { key: role._id }, [
                              _vm._v(" " + _vm._s(role.name) + " "),
                            ])
                          }),
                          _vm._l(_vm.roles, function (role) {
                            return _c(
                              "v-tab-item",
                              { key: role._id, staticClass: "pl-10" },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-card-text",
                                      { staticClass: "pt-0 d-flex" },
                                      [
                                        _c("v-simple-table", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "tbody",
                                                      _vm._l(
                                                        role.rights,
                                                        function (
                                                          item,
                                                          item_key
                                                        ) {
                                                          return _c(
                                                            "tr",
                                                            { key: item_key },
                                                            [
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.tableText(
                                                                      item_key
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center",
                                                                },
                                                                [
                                                                  item ===
                                                                  "EDIT"
                                                                    ? _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-pencil-box-outline "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : item ===
                                                                      "VIEW"
                                                                    ? _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-eye "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-cancel "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "ml-1",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }